
import { Link } from 'react-router-dom';
import React from "react";
import ButtonPrimary2 from "../../atoms/control/ButtonPrimary2";

const Physical: React.FC = () => {
  return (
    <section className="bg-join-bg bg-cover">
      <div className="w-11/12 md:w-11/12 mx-auto">
        <div className="flex gap-10 flex-col lg:flex-row justify-between pt-20 pb-20">
          <div
            className="flex items-center justify-center flex-col gap-4 w-full"
            data-aos="slide-up"
          >
            <div
              className="background-other-video"
            >
              <video autoPlay muted loop playsInline>
                <source
                  src={'https://tekfall.s3.amazonaws.com/Compressed+box_04render_compressed.mp4'}
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="p-4 bg-white">
              <div className="text-5xl font-semibold bg-white text-black ">
                THE PHYSICAL ​ MEETS THE VIRTUAL
              </div>
            </div>
            <p className="text-center text-3xl text-white">
              TEKFALL SUPREME puts you in an immersive wrestling metaverse
              community by BATS-TOI, exclusive digital games.
            </p>
            <Link rel="prefetch" className="text-1xl" to="/daplearn">
              <ButtonPrimary2 text="Learn More" />
            </Link>
          </div>
          <div
            className="flex w-full justify-center w-screen items-center md:items-start md:flex-row gap-2 flex-col"
            data-aos="fade-right"
          >
            <div
              className="background-other-video lg:mt-32"
            >
              <video autoPlay muted loop playsInline>
                <source
                  src={'https://tekfall.s3.amazonaws.com/Compressed+Box_02Render_compressed.mp4'}
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Physical;
