import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

type IProps = {
  imgUrl: any,
  num: any,
  text: any,
  titText: any
}

const StackCard: React.FC<IProps> = ({ imgUrl, num, text, titText }) => {
  return (
    <div
      className="relative flex h-full items-end flex-col sm:flex-row gap-8"
      style={{ maxWidth: "100%", minWidth: "fit-content" }}
    >
      <div className="flex w-8/12 h-full flex-col items-start z-10 transform translate-x-0/4 -translate-y-0/4">
        <div className="flex flex-col items-start">
          <h6 className="text-green-300 p-4 px-6 text-xl font-bold" 
            style={{background: '#404040', color: '#66ff66'}}>{num}</h6>
          <div className="p-4 bg-white text-left flex flex-col gap-2">
            <div className="text-black text-5xl font-semibold">{titText}</div>
            <p className="text-black">{text}</p>
          </div>
        </div>
      </div>
      <div className="absolute w-full bottom-0 top-12 left-12 right-0 transform translate-x-2/4 translate-y-3/4">
        <LazyLoadImage
          src={imgUrl}
          alt="img"
          style={{
            width: "100%",
            maxWidth: "450px",
            height: "350px",
          }}
        />
      </div>
    </div>
  );
};

export default StackCard;
