import React from "react";
import { INavButton } from "../../utils/types";
import { Link } from 'react-router-dom';

const NavBtn: React.FC<INavButton> = () => {
  return (
    <div className="hidden flex-grow-0 md:block flex-shrink duration-300">
      <Link rel="prefetch" to="/preorderform">
        <button className="button nav-button-pre">PRE-ORDER NOW</button>
      </Link>
    </div>
  );
};

export default NavBtn;
