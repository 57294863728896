import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AboutUs: React.FC = () => {
  return (
    <section className="bg-bg-lat bg-black">
      <div className="w-11/12 md:w-11/12 mx-auto pt-20">
        <h3 className="text-xl lg:text-3xl text-center text-white mb-4 font-semibold">
          ABOUT US
        </h3>
        <div className="xl:pb-20 h-full pb-10 px-4">
          <div className="flex flex-col lg:flex-row h-full gap-8 items-center">
            <div className="w-full">
              <div className="flex flex-row flex-wrap justify-between items-center w-full">
                <div className="mt-3 text-sm w-5/12 leading-8 mobile-setup text-white">
                  
                  <h3 className="text-xl lg:text-3xl text-white mb-4 font-semibold">
                    BAT - XP
                  </h3>
                  <p>
                  A division of BATS-TOI, BAT-XP is a full-service CGI production and 
                  video game development studio. There, the in-house team develops and 
                  utilizes various cutting-edge technologies, tools and processes to 
                  create immersive digital content and virtual experiences.
                  </p>
                  <p>
                  The services and capabilities of BAT-XP includes custom full-body 3D motion 
                  capture, CGI animation, custom product renders, advance web application, and video game development across all platforms. 
                  </p>
                  <p>
                  The BAT-XP team consists of highly experienced 2D/3D modulars, programmers, 
                  technical animators, and software and game engine developers.
                  </p>
                  <div className="flex flex-row justify-between flex-wrap w-auto min-h-full gap-6 mt-8">
                <LazyLoadImage 
                  src="https://tekfall-home.s3.amazonaws.com/160714+45_teamshot+(1).jpg"
                  className="bg-light-blue w-6/12 mobile-setup rounded-lg"
                  style={{ 
                    height: 'inherit',
                    // backgroundImage: 'url("https://tekfall-home.s3.amazonaws.com/160714+45_teamshot+(1).jpg")',
                    // backgroundSize: "cover",
                    // backgroundPosition: "center",
                  }}
                />
                <LazyLoadImage
                  src="https://tekfall-home.s3.amazonaws.com/20230408_122007.jpg"
                  className="bg-light-blue w-5/12 mobile-setup rounded-lg"
                  style={{ 
                    height: 'inherit',
                    // backgroundImage: 'url("https://tekfall-home.s3.amazonaws.com/160714+45_teamshot+(1).jpg")',
                    // backgroundSize: "cover",
                    // backgroundPosition: "center",
                  }}
                />
                  {/* Add the background image styling to the container */}
                  {/* <div className="bg-opacity-75 bg-black rounded-lg">
                    <span className="text-3xl block font-extrabold text-white">10K</span>
                    <h4 className="text-lg font-semibold my-2 text-white">Virtual Creations</h4>
                    <p className="mb-0 text-text leading-7 text-sm text-white">
                      Digital created character by for you.
                    </p>
                  </div> */}
                {/* </div> */}
              </div>
                </div>
                
                <div 
                  className="w-6/12 mobile-setup m-h-auto"
                  style={{
                    border: "1px solid #66ff66",
                    background: "#404040",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px 0px #66ff66",
                  }}>
                  <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className=""
                    style={{ maxHeight: "570px", width: "100%" }}
                  >
                    <source
                      src="https://tekfall-home.s3.amazonaws.com/Compressed+about_compressed.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              
            </div>
            {/* <LazyLoadImage
              src="/bats-toi-blab_intro-bg_mobile.jpg"
              alt="aboutus"
              className="rounded-lg object-cover w-full lg:w-2/5"
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
