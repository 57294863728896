import React from "react";
import { IButton } from "../../utils/types";
import { Link } from 'react-router-dom';

const ButtonOutline2: React.FC<IButton> = ({ onClick, text, to, target }) => {
  return (
    <>
      {!target ? 
        <Link rel="prefetch" className="button button-primary2" to={to}>{text}</Link>
        :
        <a href={to} className="button button-primary2" target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      }
    </>
  );
};

export default ButtonOutline2;
