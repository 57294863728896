import React from "react";
import ButtonOutline from "../../atoms/control/ButtonOutline";
import { Link } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";

const LearnMoreInfo: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex mobileView relative left-0 right-0 items-center w-8/12 justify-center flex-col gap-4">
        <LazyLoadImage
          src="https://tekfall.s3.amazonaws.com/MetaMotionAI_logo_1__1_-removebg-preview.png"
          className="py-5 w-full flex-shrink px-2 md:py-2"
          // style={{ maxWidth: "800px", width: "100%" }}
        />
        <div className=" text-4xl font-semibold text-center font-semibold text-white">
          REAL ANIMATIONS PUTS YOU ON THE MAT
        </div>
        <Link rel="prefetch" className="text-1xl" to="/mocap">
          <ButtonOutline text="Learn More​" />
        </Link>
      </div>
    </div>
  );
};

export default LearnMoreInfo;
