
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ComingSoonPage: React.FC = () => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Set the target date for the countdown
    const targetDate: any = new Date("2023-12-31");

    // Calculate the remaining time
    const calculateCountdown = () => {
      const now = new Date().getTime();
      const timeRemaining = targetDate - now;

      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 220));
      const hours = Math.floor(
        (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    };

    // Update the countdown every second
    const countdownInterval = setInterval(calculateCountdown, 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(countdownInterval);
  }, []);

  return (
    <div style={{backgroundColor: 'black'}} className="bg-bg-lat w-screen py-10 min-h-screen flex flex-col items-center justify-center text-white font-bold">
         <div className="w-8/12">
            <Link rel="prefetch" to={"/"} className={`w-full cursor-pointer`}>
                <div className="w-full">
                    <LazyLoadImage
                        
                        loading="lazy"
                        src={'https://tekfall-home.s3.amazonaws.com/tekfallsupreme_logo_brandw.png'}
                        alt="TEKFALL SUPREME"
                        className="w-1/2"
                        style={{ width: "100%" }}
                    />
                </div>
            </Link>
        </div>
        <h4 
            id="textMobile"
            style={{color: ' #66ff66'}}
            className="animate-bounce italic text-8xl sm:text-5xl text-center font-extrabold">
            SOCIAL WALL
        </h4>
      <div
        style={{flexWrap: 'wrap',}} 
        className="flex px-10 flex-row w-full min-h-full justify-between">
        <div id="comingsoon-mobile"
            style={{
            border: "1px solid #66ff66",
            color: '#404040',
            borderRadius: '10px',
            boxShadow: "0px 0px 10px 0px #66ff66",
        }} className="p-5 px-12 text-5xl min-w-3/12 min-h-3/12 py-10 flex flex-col justify-center items-center countdown-item">
          <div className="countdown-value">{countdown.days}</div>
          <div className="countdown-label">Days</div>
        </div>
        <div id="comingsoon-mobile"
            style={{
            border: "1px solid #66ff66",
            color: '#404040',
            borderRadius: '10px',
            boxShadow: "0px 0px 10px 0px #66ff66",
        }} className="p-5 px-12 text-5xl min-w-3/12 min-h-3/12 py-10 flex flex-col justify-center items-center countdown-item">
          <div className="countdown-value">{countdown.hours}</div>
          <div className="countdown-label">Hours</div>
        </div>
        <div id="comingsoon-mobile"
            style={{
            border: "1px solid #66ff66",
            color: '#404040',
            borderRadius: '10px',
            boxShadow: "0px 0px 10px 0px #66ff66",
        }} className="p-5 px-12 text-5xl min-w-3/12 min-h-3/12 py-10 flex flex-col justify-center items-center countdown-item">
          <div className="countdown-value">{countdown.minutes}</div>
          <div className="countdown-label">Minutes</div>
        </div>
        <div id="comingsoon-mobile"
            style={{
            border: "1px solid #66ff66",
            color: '#404040',
            borderRadius: '10px',
            boxShadow: "0px 0px 10px 0px #66ff66",
        }} className="p-5 px-12 text-5xl min-w-3/12 min-h-3/12 py-10 flex flex-col justify-center items-center countdown-item">
          <div className="countdown-value">{countdown.seconds}</div>
          <div className="countdown-label">Seconds</div>
        </div>
      </div>
      
      <h1 style={{
            color: '#404040',
            boxShadow: "0px 0px 10px 0px #66ff66",
        }} className="text-4xl p-4 mt-10 mb-10">#ComingSoon</h1>
        <div className="flex-grow-0 flex-shrink duration-300">
        <Link rel="prefetch" to="/preorderlist">
          <button className="button nav-button-signin">WatchOut...</button>
        </Link>
    </div>
    </div>
  );
};

export default ComingSoonPage;