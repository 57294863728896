// PrivacyPolicy.tsx

import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto px-4">
        <div className="bg-white rounded-lg shadow-md p-8">
            <h1 className="text-3xl font-semibold mb-4 w-full text-center">TekFall Supreme Privacy Policy</h1>

            <div className="container mx-auto py-8">
                {/* <h1 className="text-3xl font-bold mb-4">TekFall Supreme Privacy Policy</h1> */}
                <p className="mb-4">This Privacy Policy explains how Split Sole Games, Inc (“TekFall Supreme”) collects, uses, stores, protects, and shares your personal information when you use our services. Your privacy is important to TekFall Supreme and we take our responsibility of caring for it seriously.</p>
                <h2 className="text-xl font-bold mb-2">1. Information We Collect</h2>
                <p className="mb-4">The information we collect depends on what services you use and how you use them. Our services include the TekFall Supreme app, which can be accessed on console, desktop, or mobile devices. We also have a website and social wall, and we may collect your information through other means like surveys, emails, and social media. If we build another product, our services include that too.</p>
                <h3 className="text-lg font-bold mb-2">A. Information You Give Us Directly</h3>
                <ul className="list-disc pl-4 mb-4">
                    <li>Your account information, including your email address, username, real name, country, date of birth, telephone number, and password;</li>
                    <li>Security questions and answers;</li>
                    <li>Information you provide in response to surveys about our services;</li>
                    <li>Information you provide when seeking help from us, such as your name, telephone number, and records (including call recordings) of the issues you experience.</li>
                </ul>
                <h3 className="text-lg font-bold mb-2">B. Information You Provide When Playing Online</h3>
                <p className="mb-4">When you use our social wall or other social features, any text, audio, visual, and/or other electronic communications information that you disclose may be read, copied, collected, or used by other players, and may become publicly available. Please make sure your privacy settings reflect your preferences.</p>
                <p className="mb-4">When you play our online games, your in-game profile information, gameplay, and statistics are collected and some may be visible in-game, as well as visible to players outside the game. We may record your gameplay and replay it to other players in-game and/or make it visible to other players outside the game in other TekFall Supreme services. Leaderboards may also be available to view by other players outside the game in other TekFall Supreme services. In competitive game modes, we may record your gameplay, and your controller button inputs, and replay these together with your in-game profile information and game statistics to other players in-game and at live TekFall Supreme or partner events.</p>
                <h3 className="text-lg font-bold mb-2">C. Other Information We Collect When You Use Our Services</h3>
                <ul className="list-disc pl-4 mb-4">
                    <li>IP address;</li>
                    <li>Information about your device, hardware, and software, such as your hardware identifiers, mobile device identifiers (like Apple Identifier for Advertising [IDFA], or Android Advertising ID [AAID]), platform type, settings and components, TekFall Supreme software and updates you have installed, and the presence of required plugins;</li>
                    <li>Approximate geolocation data (derived from IP or device settings);</li>
                    <li>Browser information, including your browser type and the language preference;</li>
                    <li>Referring and exit pages, including pages viewed and other interactions with web content;</li>
                    <li>Details about what services you purchase or obtain, and your use of them;</li>
                    <li>Device event information, including crash reports, request and referral URLs, and system activity details (e.g., whether you encountered an error playing our games or lost Internet access); and</li>
                    <li>Other information (such as your likeness) that you may provide as part of your participation in live events.</li>
                </ul>
                <p className="mb-4">We also may collect and store information locally on your device, using mechanisms like cookies, browser web storage (including HTML 5), and application data caches.</p>

                <h3 className="text-lg font-bold mb-2">D. Information Provided to Us by Third Parties</h3>
                <p className="mb-4">We may receive information about you contained in third-party user accounts when you use TekFall Supreme online services on or through third-party gaming platforms, such as those listed below. This information is provided to TekFall Supreme as part of establishing or linking a TekFall Supreme Account, which is necessary to access TekFall Supreme’s online services. Your use of TekFall Supreme online services through these third parties indicates your agreement for the transfer of this information. Examples of information we receive are provided below. We receive your credit card number and financial information through our Stripe payment gateway.</p>
                <p className="mb-4">PlayStation®3 system, PlayStation®4 system, & PlayStation®5 console. Sony provides information from your account for PlayStation™Network such as your Online ID, email address, country, language, friend list, date of birth, and certain preferences.</p>
                <p className="mb-4">Xbox network. Microsoft provides information such as your Gamertag, email address, country, language, friend list, date of birth, and certain preferences.</p>
                <p className="mb-4">Social Gaming Platforms. When you use third-party social gaming platforms (such as Discord or Twitch) and link your TekFall Supreme Account, we may receive information associated with your account on those platforms. Please review the notices presented to you when linking your accounts for details on the information TekFall Supreme receives.</p>
                <p className="mb-4">Mobile Platforms. If you play or purchase our games on your mobile device or tablet, we receive information about you from the app stores and other mobile platform providers. This information may include your username or device ID and the fact that you made a purchase, as applicable, but does not include any financial information. Some of our mobile platforms may send information to us that you authorize them to provide. Our mobile games also may request additional personal information from you, such as push notification tokens or contact list, however, we will collect this information only if you provide us with permission.</p>
                <h2 className="text-xl font-bold mb-2">E. Legal Basis for Processing</h2>
                <p className="mb-4">TekFall Supreme is available globally via third-party gaming platforms (e.g. Sony, PlayStation, Microsoft XBox, Epic Online, Twitch). Our legal basis for processing your personal information varies depending on the type of information we collect, the context in which we collect it, as well as on your country of residence, as indicated when you are setting up your TekFall Supreme Account or, when not signed into a TekFall Supreme Account, your location as determined by your IP address and/or your device registration country.</p>
                <p className="mb-4">If you are a resident of the European Economic Area (EEA) or of a jurisdiction where similar legal requirements may apply such as Brazil, Switzerland, and the United Kingdom, we rely on a number of legal bases to process information about you. We will process information about you where we have your consent, where we have a legitimate interest to do so, where the processing is necessary for the performance of a contract with you, and/or where we have a legal obligation to process your information. For example, we typically rely on:</p>
                <ul className="list-disc pl-4 mb-4">
                    <li>Consent when placing cookies on your device on the web (through our cookie consent manager), to send you email marketing, or to deliver third-party targeted advertising to you on our services;</li>
                    <li>Our legitimate interests to process your information when providing you with customer service support, to serve targeted TekFall Supreme marketing of our services, or for fraud prevention and security purposes; and</li>
                    <li>On contractual necessity to process information when you create a TekFall Supreme Account or otherwise use our services to enable us to provide you with our services.</li>
                </ul>
                <p className="mb-4">If you are a resident in a territory where our legitimate interests mentioned above are not recognized as a lawful basis under applicable law, we will identify and use other appropriate lawful bases to process your personal information such as contractual necessity or your consent.</p>

                <h2 className="text-xl font-bold mb-2">2. How We Collect Your Information</h2>

                <h3 className="text-lg font-bold mb-2">A. Cookies and Similar Technologies</h3>
                <p className="mb-4">Cookies are small text files stored on your Internet browser. Our third-party partners use cookies and similar tracking technologies to help us understand things like what web pages, features, or ads you view and what games you play. This information helps us keep track of your shopping cart, measure the effectiveness of our advertising, make sure you don't see the same ad repeatedly, and otherwise enhance your experience.</p>

                <h3 className="text-lg font-bold mb-2">B. Analytics Technologies</h3>
                <p className="mb-4">We use third-party analytics technologies to collect information about how you use our Services, your computer or device and the health of our Services.</p>
                <p className="mb-4">Third-party analytics technologies integrated into our Services may (including SDK [Software Development Kit] and API [Application Program Interface] integrations) combine the information they collect in connection with your use of TekFall Supreme’s Services with information they have independently collected over time and/or across different platforms. Many of these companies collect and use information pursuant to their own privacy policies.</p>
                <h2 className="text-xl font-bold mb-2">C. Ad Serving Technologies</h2>
                <p className="mb-4">Some of our Services employ ad serving technologies to deliver offers and advertising to you within TekFall Supreme as well as on third-party platforms and sites, and to measure the performance of our advertising campaigns. Some of these technologies can sync or connect behavior across different websites, mobile apps and devices to tailor offers and advertising to your interests.</p>
                <p className="mb-4">These technologies collect and use information so we can serve appropriate offers and advertising to you, to measure effectiveness and distribution of these offers and advertising, and to understand how users interact with our Services. This information may include:</p>
                <ul className="list-disc pl-4 mb-4">
                    <li>Advertisements served and the location of the ad;</li>
                    <li>Length of time an advertisement was visible;</li>
                    <li>Domain type, IP address, size of the advertisement;</li>
                    <li>Your interaction, if any, with the ad; and</li>
                    <li>Advertisement response.</li>
                </ul>
                <p className="mb-4">Third-party advertising companies may combine the information collected in the context of delivering an offer to you via our Services with other information they have independently collected over time and/or across different websites. Many of these companies collect and use information pursuant to their own privacy policies.</p>

                <h2 className="text-xl font-bold mb-2">D. Anti-Cheat and Fraud Prevention Technologies</h2>
                <p className="mb-4">We strive to provide a safe and fair gaming environment for all players. When you use our Services, we or third parties may use cookies and similar technologies and collect data about your machine or device, to create a machine "fingerprint" or "hash" of your machine components, for fraud prevention, security and authentication purposes. We may also monitor publicly-available information, third-party sites, and/or use anti-cheat technology within our Services, including automated anti-fraud and abuse algorithms.</p>
                <p className="mb-4">Some of our third party partners like Epic use a variety of anti-cheat and fraud prevention technologies to help us identify and prevent malicious activity. They may collect and analyze data about your computer and the software on your computer to detect cheating. You should consult Epic’s privacy policy for more information on how they use your information for these purposes.</p>

                <h2 className="text-xl font-bold mb-2">3. How We Use Your Information</h2>

                <p> We use your personal information, both individually and combined together, in the following ways:</p>
                <ul className="list-disc pl-4 mb-4">
                    <li>To operate our business and to enhance and personalize your game experience, including to:</li>
                    <li>Operate, analyze, improve, and develop our games and services;</li>
                    <li>Undertake research and analysis to develop and demonstrate our technology, and to better understand our players;</li>
                    <li>Populate online leaderboards and enable online matchmaking;</li>
                    <li>Provide replays to you and other players via spectator modes;</li>
                    <li>Help you find your friends or tell your friends about a TekFall Supreme game or service;</li>
                    <li>Facilitate sharing on social networks;</li>
                    <li>Manage and serve advertising in-game and on third-party platforms;</li>
                    <li>Measure the effectiveness and distribution of advertising, including understanding who engages with our services and how they interact with our services;</li>
                    <li>Measure the health of our services;</li>
                    <li>Detect security incidents, protect against malicious, deceptive, fraudulent or illegal activity, and prosecute those responsible for that activity;</li>
                    <li>Set up and maintain accounts for which you register;</li>
                    <li>Provide game experiences that you may like, including personalizing your game experience;</li>
                    <li>Set up and facilitate loyalty programs;</li>
                    <li>Facilitate your gameplay on multiple devices and/or platforms, as applicable;</li>
                    <li>Identify, fix, and troubleshoot bugs or functionality errors;</li>
                    <li>Provide software updates;</li>
                    <li>Authenticate and activate your games and save game-play data, including progress;</li>
                    <li>Facilitate competitions and contests;</li>
                    <li>Perform audits, transactions, and other compliance activities;</li>
                    <li>Adhere to your preferences and to deliver content;</li>
                    <li>Help keep our services safe and fair, resolve disputes, investigate and help curb fraud and illegal behavior, comply with the law, and to enforce our agreements and policies; and</li>
                    <li>Other purposes you consent to, are notified of, or are otherwise disclosed to you when you provide personal information.</li>
                </ul>

                <p>Retention. We retain the information we collect for as long as necessary to provide our services, and we may retain that information beyond that period if necessary for legal, operational, or other legitimate reasons.</p>
                <p>Where possible, we may also de-identify, anonymize, or aggregate the information we collect, or collect it in a way that does not directly identify you. We may use and share such information as necessary for our business purposes and as permitted by applicable law.</p>
                <h2 className="text-xl font-bold mb-2">4. Who May Receive Your Information</h2>

                <p>Service providers. Vendors and other service providers that help us operate our business and provide you with services may receive your personal information. We require that any service provider processing your personal information only do so on our behalf and for purposes consistent with this policy.</p>

                <p>Business partners. We may provide personal information to business partners with whom we jointly offer services. These partners include the companies that provide the platform on which you play our games, such as Sony (PlayStation®4 system or PlayStation®5 console), Microsoft (Xbox), or others.</p>

                <p>Affiliates and subsidiaries. We may disclose personal information to other companies in which we have an ownership interest as necessary for the purposes outlined in this policy.</p>

                <p>Advertising partners. Through our services, we may allow third-party advertising partners to use technologies and other tracking tools to collect information regarding your use of TekFall Supreme's services and your device (such as your IP address, mobile identifiers, pages visited, location, browser information, time of day). We also may share information relating to your device and your use of our services with our third-party advertising partners so they may deliver targeted advertisements to you when you visit third-party services and websites within their networks. This practice is commonly referred to as "interest-based advertising," "online behavioral advertising," or "targeted advertising."</p>

                <p>Disclosures to protect us or others. We may access, preserve, and disclose any information we store associated with you to external parties if we, in good faith, believe doing so is required or appropriate to: comply with law enforcement or national security requests and legal process, such as a court order or subpoena; protect your, our or others' rights, property, or safety; enforce our policies or contracts; collect amounts owed to us; or assist with an investigation or prosecution of suspected or actual illegal activity. In particular, if we receive a report that you have violated our policies, we may disclose that report and other relevant information to the platform on which you play our games.</p>

                <p>Disclosure in the event of merger, sale, or other asset transfer. In the event of a reorganization, divestiture, merger, sale, or bankruptcy, we may transfer all information we collect to the relevant third party and will obtain your consent to do so if required by law or contract.</p>

                <p>Third-party plugins. When third-party technologies or social tools are integrated into our services, those third parties may collect information about your use of our services. An example is the Facebook "Like" button.</p>

                <p>Other platforms receiving your information may include Sony (PlayStation®4 system or PlayStation®5 console), Microsoft (Xbox), Nintendo (Nintendo Switch), Valve (Steam), Epic Games, Amazon (Gamelift), Apple (iOS), Alphabet (Android) and additional platforms as they become available for these features. Your information received by these platforms may be subject to their respective privacy notices.</p>

                <p>With your consent. We may disclose your information to other recipients with your express consent, unless otherwise described above. For example, you may agree to us sharing your information with a certain company or organization to hear about their products, services, or promotions, or if you tell us that you want to link your TekFall Supreme Account with another company’s services. These recipients will process the information you agree to share according to their respective privacy notices.</p>
                <h2 className="text-xl font-bold mb-2">5. Where We Hold Your Information</h2>
                <p>When you access TekFall Supreme services, including when available on third-party platforms, your personal information is generally collected by TekFall Supreme directly and processed in the United States. Personal information we collect may be subsequently stored and processed for the purposes set out in this Privacy and Cookie Policy in the United States or any other country in which TekFall Supreme operates. By consenting to the transfer of your personal data outside of your country of residence, you acknowledge that your personal information may be transferred to recipients in the United States and other countries that may not offer the same level of privacy protection as the laws in your country of residence or citizenship.</p>

                <h2 className="text-xl font-bold mb-2">6. How We Protect Your Information</h2>
                <p>Security of your information is a priority at TekFall Supreme and we take a range of technical and organizational measures to help protect it, including encryption of sensitive information. Even so, please be aware that no security measure is perfect. Thus we cannot guarantee the security of your information at all times. You should always be diligent when it comes to the protection of your personal information.</p>

                <h2 className="text-xl font-bold mb-2">7. Children</h2>
                <p>Although the majority of TekFall Supreme services are intended for adults, TekFall Supreme recognizes the importance of protecting the privacy of children who may access our services online. We strive to ensure that children who access our services receive age-appropriate experiences by employing data minimization, privacy by design, and default practices. We consider the best interests of children when determining the age-appropriate experience they receive and take into account the developmental maturity of children of different ages.</p>
                <p>TekFall Supreme is rated E for everyone as a non-violent and simulated sports video game. If we make material changes to how we collect, use, share or otherwise process your child's personal information, we will notify parents as required by applicable law.</p>
                <p>We encourage you to talk with your children about communicating with strangers and the risks of disclosing personal information online. You and your child should review the privacy settings applicable to their use of our services.</p>
                <h2 className="text-xl font-bold mb-2">8. Your Choices and Controls</h2>
                <p>We give you meaningful choices when it comes to our collection and use of your information. To access, correct, or delete data from our servers, account administrators can initiate the process of updating or deleting their user details from within the application.</p>

                <h2 className="text-xl font-bold mb-2">9. Third-Party Services</h2>
                <p>Our websites and services may contain links to third-party services. If you click on those links, you will leave the TekFall Supreme Service and go to the third-party service or site that you selected. If you visit a third-party website or use a third-party service, you should consult that service's or site's privacy policy as your use of third-party sites or services is governed by their privacy policies.</p>

                <h2 className="text-xl font-bold mb-2">10. Information for California Residents</h2>
                <p>Depending on your use of our services, the following categories of personal information may have been collected and disclosed in the preceding 12 months, as these categories are defined under the California Consumer Privacy Act ("CCPA"):</p>

                <ul className="list-disc pl-4 mb-4">
                    <li>Identifiers; such as a real name, user name, account name, email, online identifiers, device identifiers, IP address, or mobile device identifiers;</li>
                    <li>Account login data; we collect and process the credentials necessary for you to access your TekFall Supreme Account; for any third-party connected accounts, following your authorization, we collect an access token that allows us to receive information from that third-party specific to your account with them;</li>
                    <li>Purchase information; such as payment card number, payment account details, billing address, and other payment information;</li>
                    <li>Characteristics of Protected Classifications under state or federal law; such as age or gender;</li>
                    <li>Commercial Information; such as products or services interaction and purchase history;</li>
                    <li>Internet or Electronic Network Activity information; such as browsing history, search history, and information regarding interactions with our games, websites, applications, and/or advertisements;</li>
                    <li>Approximate (coarse) geolocation data; such as location data derived from IP address or device settings;</li>
                    <li>Audio or Electronic information; such as when you play our games, play certain multiplayer modes, use chat features, or participate in competitive game modes or live events;</li>
                    <li>Contents of players' in-game messaging;</li>
                    <li>Inferences; such as consumer preferences drawn from the above.</li>
                </ul>

                <p>In certain circumstances, we may also collect and disclose additional information, such as:</p>
                <ul className="list-disc pl-4 mb-4">
                    <li>If you are the recipient of a contest or sweepstakes prize that requires tax reporting, we may collect identifiers associated with your government-issued documents (such as a social security number).</li>
                    <li>If you participate in our competitive gaming events, we may collect identifiers associated with your government-issued documents (such as a passport number), financial information (such as bank accounts), and health-related information.</li>
                    <li>If you participate in our user experience research, we may collect financial information (such as bank accounts), health-related information and video footage of your gameplay, as well as your recorded image throughout the session.</li>
                    <li>We may collect and disclose other categories of personal information to the extent that you provide them through in-game chat, forums, or through our player support channels.</li>
                </ul>

                <p>The following categories of personal information may be "sold" or "shared" as those terms are defined under the CCPA, for purposes of targeted advertising:</p>

                <ul className="list-disc pl-4 mb-4">
                    <li>Identifiers; such as a real name, user name, account name, email, online identifiers, device identifiers, IP address, or mobile device identifiers;</li>
                    <li>Characteristics of Protected Classifications under state or federal law; such as age or gender;</li>
                    <li>Commercial Information; such as products or services interaction and purchase history;</li>
                    <li>Internet or Electronic Network Activity information; such as, browsing history, search history, and information regarding interactions with our games, websites, applications, and/or advertisements;</li>
                    <li>Approximate (coarse) geolocation data; such as location data derived from IP address or device settings;</li>
                    <li>Audio or Electronic information; such as when you play our games, play certain multiplayer modes, use chat features, or participate in competitive game modes or live events;</li>
                    <li>Inferences; such as consumer preferences drawn from the above.</li>
                </ul>

                <p>We do not "sell" or "share," as those terms are defined under the CCPA, personal information of consumers.</p>

                <p>We retain the information we collect for as long as necessary to provide our services, and we may retain that information beyond that period if necessary for legal, operational, or other legitimate reasons.</p>
                <div className="mt-8">
                    <h2 className="text-lg font-semibold mb-2">Changes to Our Policy</h2>
                    <p className="mb-4">
                        We may occasionally update this privacy policy. When we do, we will revise the "last updated" date at the bottom of the policy. If there are material changes to this policy, we will use reasonable efforts to notify you either by prominently posting a notice of such changes before they take effect or by directly sending you a notification.
                    </p>
                </div>

                {/* 12. Contact Us */}
                <div className="mt-8">
                    <h2 className="text-lg font-semibold mb-2">Contact Us</h2>
                    <p className="mb-4">
                        If you have questions about this policy, please contact us at <a href="mailto:help@splitsolegames.com" className="text-blue-500">help@splitsolegames.com</a>.
                    </p>
                    <p>
                        To contact TekFall Supreme, you can also use the following address:
                        <br />
                        Split Sole Games, Inc.,
                        <br />
                        19 Morris Avenue, Bldg 128,
                        <br />
                        Brooklyn, NY 11205
                    </p>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
