// import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/scrollbar";
// import "swiper/css/pagination";

// import { Pagination, Scrollbar } from "swiper/modules";
// import StackCard from "../../atoms/card/StackCard";
// import ButtonCus from "../../atoms/control/BtnOutCus";
// import ButtonPrimary from "../../atoms/control/ButtonPrimary";
// import BtnFill from "../../atoms/control/BtnFill";
// import ButtonPrimary2 from "../../atoms/control/ButtonPrimary2";
// import { Link } from 'react-router-dom';

// export default function SwiperCom() {
//   return (
//     <section className="bg-join-bg bg-cover">
//       <Swiper
//         scrollbar={{ hide: false }}
//         className="mySwiper"
//         modules={[Scrollbar]}
//         navigation={true}
//         // pagination={{ clickable: true }}
//       >
//         <SwiperSlide>
//           <section className="bg-join-bg bg-cover py-20 ">
//             <div className="w-11/12 md:w-11/12 mx-auto" data-aos="fade-right">
//               <div className="flex flex-col gap-8 items-center justify-center">
//                 <StackCard
//                   imgUrl={"/ane.jpg"}
//                   num={"1"}
//                   titText="CREATE, COLLECT, TRADE & PLAY"
//                   text="Build your collection of DAP Drops to wear or trade in TEKFALL SUPREME or to show in AR/VR experiences."
//                 />
//                 <Link className="text-1xl" to="/daplearn">
//                   <ButtonPrimary2 text="Learn More" />
//                 </Link>
//               </div>
//             </div>
//           </section>
//         </SwiperSlide>
//         <SwiperSlide>
//           <section className="bg-join-bg bg-cover py-20">
//             <div className="w-11/12 md:w-11/12 mx-auto">
//               <div className="flex flex-col items-center gap-8 justify-center">
//                 <StackCard
//                   imgUrl={"/Arena.png"}
//                   num={"2"}
//                   titText="UNLOCK SPECIAL ACCESS"
//                   text="Collect DAP tokens and game packs that unlock real-world items & connected experiences."
//                 />
//                 <Link className="text-1xl" to="/daplearn">
//                   <ButtonPrimary2 text="Learn More" />
//                 </Link>
//               </div>
//             </div>
//           </section>
//         </SwiperSlide>
//         <SwiperSlide>
//           <section className="bg-join-bg bg-cover py-20">
//             <div className="w-11/12 md:w-11/12 mx-auto">
//               <div className="flex flex-col items-center gap-8 justify-center">
//                 <StackCard
//                   imgUrl={"Commen.png"}
//                   num={"3"}
//                   titText="BUILD YOUR COMMUNITY"
//                   text="Connect with friends to create & join teams to compete against other players."
//                 />
//                 <Link className="text-1xl" to="/daplearn">
//                   <ButtonPrimary2 text="Learn More" />
//                 </Link>
//               </div>
//             </div>
//           </section>
//         </SwiperSlide>
//       </Swiper>
//     </section>
//   );
// }

import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import ButtonPrimary2 from "../../atoms/control/ButtonPrimary2";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import CardOne from "./CardOne";
import CardTwo from "./CardTwo";
import CardThree from "./CardThree";
import MoblieStackCard from "../../atoms/card/MobileStackCard";
import MobileSecondStackCard from "../../atoms/card/MobileSecondStackCard";

const SwiperCom: React.FC<any> = () => {
  
  const [swiper, setSwiper] = useState<any | null>(null);

  // interface MySwiperProps extends SwiperProps {
  //   autoHeight?: boolean;
  // }

  const handlePrevSlide = () => {
    if (swiper) {
      if (swiper?.activeIndex === 0) {
        swiper.slideTo(swiper?.slides.length - 1);
      } else {
        swiper?.slidePrev();
      }
    }
  };

  const handleNextSlide = () => {
    if (swiper) {
      if (swiper?.activeIndex === swiper?.slides.length - 1) {
        swiper?.slideTo(0);
      } else {
        swiper?.slideNext();
      }
    }
  };

  return (
    <>
    <section id="displayMobile" className="bg-join-bg bg-cover">
      <div className="swiper-container">
        <div className="flex flex-col justify-center items-center swiper-wrapper">
          <Swiper
            id="swiper-style"
            onSwiper={setSwiper}
            // navigation={{
            //   prevEl: ".swiper-button-prev",
            //   nextEl: ".swiper-button-next",
            // }}
            // loop={true}
            
            // scrollbar={{ hide: false }}
            className="mySwiper min-h-[fit-content]"
            // pagination={{ clickable: true }}
          >
            <SwiperSlide>
              <section className="bg-join-bg bg-cover py-20">
                <div className="w-11/12 md:w-11/12 mx-auto" data-aos="fade-right">
                  <div className="flex flex-col gap-8 items-center justify-center">
                    <MoblieStackCard
                      imgUrl={"https://tekfall.s3.amazonaws.com/BatsToi_AR-Cards_V1-Front(1).jpg"}
                      num={"01"}
                      titText="CREATE, COLLECT, TRADE & PLAY"
                      text="Build your collection of to wear or trade in TEKFALL SUPREME or to show in AR/VR experiences."
                    />
                  </div>
                </div>
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <section className="bg-join-bg bg-cover py-20">
                <div className="w-11/12 md:w-11/12 mx-auto">
                  <div className="flex flex-col items-center gap-8 justify-center">
                    <MobileSecondStackCard
                      img1={"https://tekfall.s3.amazonaws.com/tbrender_016-1.png"}
                      vid={'https://tekfall.s3.amazonaws.com/Compressed+DAP+SECTION_+Unlock+Special+Access_MercadoIII_compressed.mp4'}
                      num={"02"}
                      titText="UNLOCK SPECIAL ACCESS"
                      text="Collect game packs that unlock real-world items & connected experiences."
                    />
                  </div>
                </div>
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <section className="bg-join-bg bg-cover py-20">
                <div className="w-11/12 md:w-11/12 mx-auto">
                  <div className="flex flex-col items-center gap-8 justify-center">
                    <MoblieStackCard
                      imgUrl={"https://tekfall.s3.amazonaws.com/tbrender_019.png"}
                      num={"03"}
                      titText="BUILD YOUR COMMUNITY"
                      text="Connect with friends to create & join teams to compete against other players."
                    />
                  </div>
                </div>
              </section>
            </SwiperSlide>
          </Swiper>
          <div className="w-full flex flex-col justify-center items-center">
            <div className="flex flex-row justify-between items-center w-4/12 flex-row mt-5 mb-10">
              <button
                style={{border: '2px solid white', marginRight: '20px'}}
                className="transform text-green rounded-full p-3"
                onClick={handlePrevSlide}
              >
                <IoIosArrowBack className="text-green-300 h-8 w-8" />
              </button>
              <button
                style={{border: '2px solid white'}}
                className="transform text-green rounded-full p-3"
                onClick={handleNextSlide}
              >
                <IoIosArrowForward className="text-green-300 h-8 w-8" />
              </button>
            </div>
          </div>
          <div className="text-1xl mb-10">
            <ButtonPrimary2 text="Learn More" to={'/daplearn'}/>
          </div>
        </div>
      </div>
    </section>

      <section 
        id="displayDesktop"
        className="bg-join-bg bg-cover flex flex-col justify-center items-center mb-10"
      >
        <CardOne />
        <CardTwo />
        <CardThree />
        <div className="text-1xl">
          <ButtonPrimary2 text="Learn More" to={'/daplearn'} />
        </div>
      </section>
    </>
  );
}
export default SwiperCom;