import React, { MouseEventHandler } from "react";
import NavAtom from "../../atoms/navatom";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from 'react-router-dom';

interface MobileNavbarProps {
  isActive: boolean;
  btnClick?: MouseEventHandler;
}

const MobileNavbar: React.FC<MobileNavbarProps> = ({ isActive, btnClick }) => {
  return (
    <>
      {isActive && (
        <section className="bg-black block lg:hidden relative">
          <div className="w-11/12 md:w-11/12 mx-auto">
            <div className="pt-10 flex flex-col">
              <AiOutlineClose
                onClick={btnClick}
                className=" text-2xl text-white self-end"
              />
              <div className="flex justify-between gap-8 pt-10 pb-20">
                <div className="flex gap-4 flex-col">
                  <NavAtom
                    // Comp={<BsPersonSquare className="text-black text-2xl" />}
                    text={"About Us"}
                    url={"/about"}
                  />
                  <NavAtom
                    // Comp={<BsPersonSquare className="text-black text-2xl" />}
                    text={"Social Wall"}
                    url={"/comingsoon"}
                  />
                  <NavAtom
                    // Comp={<BsPersonSquare className="text-black text-2xl" />}
                    text={"Metamotion AI"}
                    url={"/mocap"}
                  />
                </div>
                <div className="flex gap-4 flex-col">
                  <Link rel="prefetch" to={'https://batstoi.com'} target="_blank">
                    <div className="flex gap-1 items-center text-white">
                      {/* <div className="p-2 rounded bg-white">{Comp}</div>  */}
                      <p>BATS-TOI</p>
                    </div>
                  </Link>
                  {/* <NavAtom
                    // Comp={<BsPersonSquare className="text-black text-2xl" />}
                    text={"DAPS"}
                    url={"/daplearn"}
                  /> */}
                  <NavAtom
                    // Comp={<BsPersonSquare className="text-black text-2xl" />}
                    text={"News"}
                    url={"/blog"}
                  />
                  <NavAtom
                    // Comp={<BsPersonSquare className="text-black text-2xl" />}
                    text={"Digital Double"}
                    url={"/doublelearn"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default MobileNavbar;
