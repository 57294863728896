import React, { useEffect, useRef, useState } from "react";
import { ICardVideoCollection } from "../../utils/types";
import { FaPlay } from "react-icons/fa";

const CardVideoPlayCollection: React.FC<ICardVideoCollection> = ({
  // author,
  // authorVerified,
  // title,
  video,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setPlaying] = useState(false);

  const handlePlayPause = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.style.transform = "rotateY(90deg)";
      videoElement.style.transition = "transform 0.5s ease-in-out";
      setTimeout(() => {
        videoElement.style.transform = "rotateY(0deg)";
      }, 500);
    }
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const handlePlay = () => setPlaying(true);
      const handlePause = () => setPlaying(false);

      videoElement.addEventListener("play", handlePlay);
      videoElement.addEventListener("pause", handlePause);

      return () => {
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
      };
    }
  }, []);

  return (
    <div
      className={`relative rounded-lg duration-500 transform hover:scale-105`}
      style={{
        border: "2px solid #66ff66",
        boxShadow: "0px 0px 10px 0px #66ff66",
      }}
    >
      <p className="hidden">{isPlaying}</p>
      <div className="background-other-video">
        <video
          ref={videoRef}
          loop
          playsInline
          style={{ borderRadius: "0.5rem", cursor: "pointer" }}
          className="w-full h-full object-cover"
          onClick={handlePlayPause}
        >
          <source src={video} type="video/mp4" />
        </video>
        <div
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
          style={{ fontSize: "40px" }}
        >
          {videoRef.current?.paused ? (
            <FaPlay
              style={{ cursor: "pointer", color: "#66ff66" }}
              onClick={handlePlayPause}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CardVideoPlayCollection;