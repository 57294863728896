import React from "react";
import LazyLoad from "react-lazy-load";
import { Link } from "react-router-dom";

type IProps = {
  url: any,
  videourl: any,
  category: any,
  blogtitle: any
  author: any,
  authorurl: any,
  date: any,
  paragraph: any
}

const BlogCard: React.FC<IProps> = ({
  url,
  videourl,
  category,
  blogtitle,
  author,
  authorurl,
  date,
  paragraph,
}) => {
  return (
    <article className="flex flex-col shadow my-4">
      <Link to={url} className="hover:opacity-75">
      <LazyLoad>
        <video
          loop
          src={videourl}
          controls
          className="object-cover object-center h-full w-full cursor-pointer"
        />
      </LazyLoad>
      </Link>
      <div className="bg-white flex flex-col justify-start p-6">
        <Link to={url}
          className="text-blue-700 text-sm font-bold uppercase pb-4"
        >
          {category}
        </Link>
        <Link to={url} className="text-3xl font-bold hover:text-gray-700 pb-4">
          {blogtitle}
        </Link>
        <p className="text-sm pb-3">
          By{" "}
          <Link to={authorurl} className="font-semibold hover:text-gray-800">
            {author}
          </Link>
          , Published on {date}
        </p>
        <Link to="/blog" className="pb-6">
          {paragraph}
        </Link>
        <Link to="/blog" className="uppercase text-gray-800 hover:text-black">
          Continue Reading <i className="fas fa-arrow-right"></i>
        </Link>
      </div>
    </article>
  );
};

export default BlogCard;
