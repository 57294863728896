import React from "react";
import LearnMoreDouble from "../../components/molecules/learnmoredouble/LearnMoreDouble";

const DoubleLearn = () => {
  return (
    <div>
      <LearnMoreDouble />
    </div>
  );
};

export default DoubleLearn;
