// PrivacyPolicy.tsx

import React from 'react';

const DataDeletion: React.FC = () => {
  return (
    <div className="bg-gray-100 min-h-fit py-12">
      <div className="container mx-auto px-4">
        <div className="bg-white rounded-lg shadow-md p-8">
          <h1 className="text-3xl font-semibold mb-4">Data Deletion</h1>

          <section>
            <h2 className="text-xl font-semibold mb-2">
              About TEKFALL and this Data Deletion Instruction
            </h2>
            <p>
                TekFall is just a sporting social media application and we do not save your personal data in our server. 
                According to the Facebook Platform rules, we have to provide User Data Deletion Callback URL 
                or Data Deletion Instructions URL. If you want to delete your activities for the TekFall, 
                following these instructions:
                <ul>
                    <li>1.   Go to Your Facebook Account's Setting & Privacy. Click " Setting ".</li>

                    <li>2.   Then, go to " Apps and Websites" and you will see all of your Apps activities.</li>

                    <li>3.   Select the option box for TekFall Production</li>

                    <li>4.   Click " Remove" button.</li>
                </ul>
            </p>
            <p>
                If you wish to delete user account data, you have to request us to delete your account. 
                Subject to your TekFall user account is no longer required in the future, please send 
                your request with account registered email to  
                <a 
                    className='cursor-pointer text-blue-500 hover:test-blue-200' 
                    href="mailto:info@batstoi.com"
                > info@batstoi.com</a>. Your account will be 
                deleted and all data will no longer be saved.
            </p>
            
            </section>

        </div>
      </div>
    </div>
  );
};

export default DataDeletion;
