import React from "react";
import ButtonPrimary2 from "../../atoms/control/ButtonPrimary2";
import ButtonOutline2 from "../../atoms/control/ButtonOutline2";

const PreOrder: React.FC = () => {
  return (
    <section className=" bg-join-bg bg-cover">
      <div className="w-11/12 md:w-11/12 mx-auto py-32">
        <div className="flex flex-col gap-4 items-center">
          <section
            className="flex flex-col gap-8 text-center md:w-3/4"
            data-aos="fade-up"
          >
            {/* <h4 className="text-white text-2xl">
              THE WRESTLING VIDEO GAME YOU HAVE BEEN WAITING FOR.
            </h4> */}
            <div className="flex flex-col text-2xl gap-4">
              <ButtonOutline2 text={"PRE-ORDER NOW"} to={'/preorderform'}/>

              <ButtonPrimary2 text="JOIN NOW" target to={'https://digiyo.com'} />
            </div>
            <div className="flex flex-col gap-2 text-center text-white">
              <p>
                TEKFALL SUPREME is the most realistic digital wrestling
                experience gaming has to offer. We export you to an immersive
                virtual world where you can compete as yourself to win a
                national championship, connect with friends to create and join
                teams to compete against other players, that unlock real-world
                items and experiences, and so much more.
              </p>
              <p>Will be available on consoles, PC and mobile devices.</p>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default PreOrder;
