import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BrandWhite: React.FC<any> = ({img}) => {
  return (
    <LazyLoadImage
      src={img}
      style={{
        width: "100%",
      }}
      alt="TEKFALL SUPREME"
    />
  );
};

export default BrandWhite;
