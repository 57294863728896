import React, { useEffect, useRef } from "react";
import { ICardVideoCollection } from "../../utils/types";

const CardVideoCollection: React.FC<ICardVideoCollection> = ({
  // author,
  // authorVerified,
  // title,
  video,
}) => {
  // const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.style.transform = "rotateY(90deg)";
      videoElement.style.transition = "transform 0.5s ease-in-out";
      setTimeout(() => {
        videoElement.style.transform = "rotateY(0deg)";
      }, 500);
    }
  }, []);

  return (
    <div
      className={`relative rounded-lg duration-500 transform hover:scale-105`}
      style={{
        border: "2px solid #66ff66",
        boxShadow: "0px 0px 10px 0px #66ff66",
      }}
    >
      <div
        className="background-other-video"
      >
        <video style={{ borderRadius: "0.5rem" }}
          ref={videoRef} autoPlay muted loop playsInline>
          <source
            src={video}
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default CardVideoCollection;
