import React, { useRef, useEffect } from "react";

const CardVideoAuction: React.FC<any> = ({
  // title,
  // author,
  // authorVerified,
  video,
  // vId,
  // date,
  // expired,
  // bidPrice,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (videoElement && entry.isIntersecting) {
          videoElement.play();
        } else if (videoElement) {
          videoElement.pause();
        }
      });
    });

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  return (
    <div
      className="relative rounded-lg auction-background-video duration-500 transform hover:scale-105"
      style={{
        border: "2px solid #66ff66",
        boxShadow: "0px 0px 10px 0px #66ff66",
      }}
    >
      <video data-aos="fade-right" autoPlay muted loop playsInline className="w-full">
        <source
          src={video}
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default CardVideoAuction;
