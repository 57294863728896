import * as React from "react";
import DataDeletion from "../../components/molecules/DataDeletion";

// markup
const DataDeletionPage: React.FC = () => {
  return (
    <>
        <DataDeletion />
    </>
  );
};

export default DataDeletionPage;
