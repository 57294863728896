import React from "react";
import { Link } from 'react-router-dom';
import { INavBrand } from "../../utils/types";
// import { DarkModeContext } from "../../DarkModeProvider";
import { LazyLoadImage } from "react-lazy-load-image-component";

const NavBrand: React.FC<INavBrand> = ({ title, icon }) => {
  // const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  return (
    <div className="">
      <Link rel="prefetch" to={"/"} className={`cursor-pointer`}>
        <div style={{ maxWidth: "100px" }}>
          <LazyLoadImage
            loading="lazy"
            src={icon}
            alt="TEKFALL SUPREME"
            className="no-whitespace-svg w-full h-auto"
            style={{ width: "100%" }}
          />
        </div>
      </Link>
    </div>
  );
};

export default NavBrand;
