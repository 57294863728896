// src/App.tsx
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navigation from './components/organisms/Navigation';
import FooterNew from './components/molecules/modfooter';
import AboutUs from './components/molecules/About';
import ContactUs from './components/molecules/Contact';
import HomePage from './components/template/HomePage';
import Preorderform from './pages/preorderform';
import PreOrderListPage from './pages/preorderlist';
import Mocap from './pages/mocap';
import DoubleLearn from './pages/doublelearn';
import DapLearnMore from './pages/daplearn';
import ComingSoon from './pages/comingsoon';
import Blog from './pages/blog';
import OrderSection from './components/molecules/Order';
import PrivacyPolicyPage from './pages/privacy';
import DataDeletionPage from './pages/datadeletion';

const LazyOrder = React.lazy(() => import('./components/molecules/Order'))

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div>
        
      {/* <Helmet>
        <title>Tekfall Supreme</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <link rel="icon" type="image/png" href={'batlgBU.png'} />
      </Helmet> */}
        <Navigation />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/preorderform" element={<Preorderform />} />
          <Route path="/preorderlist" element={<PreOrderListPage />} />
          <Route path="/mocap" element={<Mocap />} />
          <Route path="/doublelearn" element={<DoubleLearn />} />
          {/* <Route path="/daplearn" element={<DapLearnMore />} /> */}
          <Route path="/privacy" 
            element={
              <React.Suspense>
                <PrivacyPolicyPage />
              </React.Suspense>
            } 
          />
          <Route path="/datadeletion" 
            element={
              <React.Suspense>
                <DataDeletionPage />
              </React.Suspense>
            } 
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/order" 
            element={
              <React.Suspense>
                <LazyOrder />
              </React.Suspense>
            } 
          />
          <Route path="/comingsoon" element={<ComingSoon />} />
        </Routes>
        <FooterNew />
      </div>
    </BrowserRouter>
  );
};

export default App;