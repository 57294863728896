import React from "react";
import ButtonPrimary2 from "../../atoms/control/ButtonPrimary2";
import { Link } from 'react-router-dom';

const LimitedOffer: React.FC = () => {
  return (
    <section className="bg-join-bg bg-cover py-20">
      <div className="w-11/12 md:w-11/12 mx-auto">
        <div className="flex items-center justify-center" data-aos="fade-down">
          <div className="flex flex-col gap-4 items-center text-center md:w-3/4 text-white">
            <h1 className="font-extrabold text-4xl xl:text-5xl">
              TEKFALL SUPREME FOUNDER’S CLUB
            </h1>
            <p>
              Pre-order TekFall Supreme by September 1, 2023 for a Founder’s DAP that unlocks an 
              exclusive digital gear pack for one of your characters which includes a singlet, 
              wrestling shoes, a headgear and a few other surprises. Also, 100 Founder’s Club members 
              will be randomly selected to receive a limited-edition TekFall Supreme T-shirt!
            </p>
            <Link rel="prefetch" className="text-1xl" to="/preorderform">
              <ButtonPrimary2 text="PRE-ORDER NOW" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LimitedOffer;
