
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const LearnMoreDouble: React.FC<any> = () => {
  
  const [swiper, setSwiper] = useState<any | null>(null);

  const handlePrevSlide = () => {
    if (swiper) {
      if (swiper?.activeIndex === 0) {
        swiper.slideTo(swiper?.slides.length - 1);
      } else {
        swiper?.slidePrev();
      }
    }
  };

  const handleNextSlide = () => {
    if (swiper) {
      if (swiper?.activeIndex === swiper?.slides.length - 1) {
        swiper?.slideTo(0);
      } else {
        swiper?.slideNext();
      }
    }
  };
  return (
    <section className="bg-join-bg min-h-screen bg-cover">
      <div className="h-full">
        <div className="flex gap-8 flex-col h-full p-5 xl:p-14 lg:p-14 md:flex-row md:pb-20 md:pt-0 justify-between">
          <div className="flex justify-center items-center sm:items-start flex-col gap-8">
            <p className="text-center sm:text-left text-white">
              Be in TEKFALL SUPREME by creating your Digital Double.
            </p>
            <p className="text-center sm:text-left text-white">
              You Own Your character. Create and join teams with your friends.
              Compete against other teams
            </p>
            <p className="text-center text-white sm:text-left">
              Leverage your followers through our NIL program.
            </p>
            <p className="text-center text-white sm:text-left">
              Create custom BATS-TOI BAT - XP for in-game
              use, and to build and connect with your community. Convert your
              BAT - XP to physical merch and experiences for your fans.
            </p>
            <p className="text-center text-white sm:text-left">
              More information Coming Soon!.
            </p>
            
          </div>
          <div className="flex flex-col justify-center items-center swiper-container">
          <Swiper
            id="swiper-style"
            onSwiper={setSwiper}
            // navigation={{
            //   prevEl: ".swiper-button-prev",
            //   nextEl: ".swiper-button-next",
            // }}
            // pagination={{ clickable: true }}
            // loop={true}
            // scrollbar={{ hide: false }}
            className="mySwiper"
            // autoHeight={true}
          >
            <SwiperSlide>
              <div className="background-digital-video h-96 w-full">
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="w-full h-full object-cover"
                >
                  <source
                    src="https://tekfall.s3.amazonaws.com/Compressec+Tori_compressed.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="background-digital-video h-96 w-full">
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="w-full h-full object-cover"
                >
                  <source
                    src="https://tekfall.s3.amazonaws.com/Compressed+Eddie_TA_compressed.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </SwiperSlide>
            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-full flex justify-between items-center z-10">
              <div className="flex justify-between items-center w-full px-4">
                <button
                  style={{ border: '2px solid white', marginRight: '20px' }}
                  className="transform text-green rounded-full p-3"
                  onClick={handlePrevSlide}
                >
                  <IoIosArrowBack className="text-green-300 h-8 w-8" />
                </button>
                <button
                  style={{ border: '2px solid white' }}
                  className="transform text-green rounded-full p-3"
                  onClick={handleNextSlide}
                >
                  <IoIosArrowForward className="text-green-300 h-8 w-8" />
                </button>
              </div>
            </div>
          </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearnMoreDouble;
