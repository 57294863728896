import React from "react";
import { INavButton } from "../../utils/types";
import { Link } from 'react-router-dom';

const NavBtn: React.FC<INavButton> = () => {
  return (
    <div className="hidden flex-grow-0 md:block flex-shrink duration-300">
      <a rel="prefetch" href="https://digiyo.com" target="_blank">
        <button className="button nav-button-signin">SIGN IN</button>
      </a>
    </div>
  );
};

export default NavBtn;
