import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

type IProps = {
  imgUrl: any,
  num: any,
  text: any,
  titText: any
}

const MoblieStackCard: React.FC<IProps> = ({ imgUrl, num, text, titText }) => {
  return (
    <div className="relative flex items-end flex-col flex-wrap" style={{ maxWidth: "100%", flexWrap: "wrap" }}>
      <div className="flex w-full h-full flex-col items-start z-10">
        <div className="flex w-full flex-col items-start">
          <h6 className="text-green-300 p-4 px-6 text-xl font-bold" 
            style={{background: '#404040', color: '#66ff66'}}>{num}</h6>
          <div className="p-4 bg-white text-left flex flex-col gap-2">
            <div className="text-black text-5xl font-semibold">{titText}</div>
            <p className="text-black">{text}</p>
          </div>
        </div>
      </div>
      <div className="w-full">
        <LazyLoadImage
          src={imgUrl}
          alt="img"
          style={{
            width: "100%",
            maxWidth: "100%",
            minWidth: "320px",
            height: "auto",
          }}
        />
      </div>
    </div>
  );
};

export default MoblieStackCard;
