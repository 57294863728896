import * as React from 'react'
import ComingSoonPage from '../../components/molecules/comingsoon'

// markup
const ComingSoon: React.FC = () => {
  return (
        <ComingSoonPage />
  )
}

export default ComingSoon