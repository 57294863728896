
import { Link } from 'react-router-dom';
import React from "react";

type IProps = {
  text: any,
  url: any
}

const NavAtom: React.FC<IProps> = ({text, url }) => {
  return (
    <Link rel="prefetch" to={url}>
      <div className="flex gap-1 items-center text-white">
        {/* <div className="p-2 rounded bg-white">{Comp}</div>  */}
        <p>{text}</p>
      </div>
    </Link>
  );
};

export default NavAtom;
