import React from "react";
import LearnMoreInfo from "../../molecules/learnmoreinfo";

const LearnMore: React.FC = () => {
  return (
    <section className="relative bg-black">
      <div className="background-video">
        <video autoPlay muted loop playsInline className="w-full">
          <source
            src="https://tekfall.s3.amazonaws.com/Eddie_TA2.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="learnmoreD" data-aos="slide-up">
        <LearnMoreInfo />
      </div>
    </section>
  );
};

export default LearnMore;
