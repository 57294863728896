import React from "react";
import { INavToggle } from "../../utils/types";
import { AiOutlineMenu } from "react-icons/ai";

const NavToggle: React.FC<INavToggle> = ({ btnClick }) => {
  return (
    <div className="flex-grow flex-shrink lg:hidden duration-300">
      <div className="block text-right">
        <AiOutlineMenu className="text-2xl font-bold" onClick={btnClick} />
        {/* <ButtonPrimary2 text="Menu" onClick={btnClick} /> */}
      </div>
    </div>
  );
};

export default NavToggle;
