import React from "react";
import { FiMapPin, FiMail } from "react-icons/fi";

const ContactUs: React.FC = () => {
  const ContactData = [
    {
      id: 1,
      title: "Email Us",
      info: "Interactively feed your day with wrestling games.",
      icon: FiMail,
      contact: "info@batstoi.com",
    },
    // {
    //   id: 2,
    //   title: "Call Us",
    //   info: "Distinctively exploit optimal entertaiment.",
    //   icon: FiPhoneCall,
    //   contact: "",
    // },
    {
      id: 3,
      title: "Location",
      info: "19 Morris Avenue Building 128 Brooklyn, NY 11205",
      icon: FiMapPin,
      contact: "",
    },
  ];
  return (
    <section className="bg-bg-lat bg-black">
      <div className="w-11/12 md:w-11/12 mx-auto py-20">
        <div className="grid mg:grid-cols-2 gap-6 lg:my-20 my-10 lg:grid-cols-2 xl:gap-8">
          {ContactData.map((item) => (
            <div
              key={item.id}
              className="border border-border w-full flex-colo p-10 bg-light-blue rounded-lg text-center"
            >
              <span className="flex-colo w-20 h-20 mb-4 rounded-full bg-main text-subMain text-2xl">
                <item.icon />
              </span>
              <h5 className="text-xl font-semibold mb-2">{item.title}</h5>
              <p className="mb-0 text-sm text-text leading-7">
                <a href={`mailto:${item.contact}`} className="text-blue-600">
                  {item.contact}
                </a>{" "}
                {item.info}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
