
import { Link } from 'react-router-dom';
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BlogAside: React.FC = () => {
  return (
    <aside className="w-full md:w-1/3 flex flex-col items-center px-3">
      <div className="w-full bg-white shadow flex flex-col my-4 p-6">
        <p className="text-xl font-semibold pb-5">About Us</p>
        <p className="pb-2">
          A division of BATS-TOI, BAT-XP is a full-service CGI production and video game development studio. 
          There, the in-house team develops and utilizes various cutting-edge technologies, tools and processes 
          to create immersive digital content and virtual experiences...<Link to="/about">read more</Link>
        </p>
        <Link
          to="/blog"
          className="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-4"
        >
          Get to know us
        </Link>
      </div>

      <div className="w-full bg-white shadow flex flex-col my-4 p-6">
        <p className="text-xl font-semibold pb-5">Instagram</p>
        <div className="grid grid-cols-3 gap-3">
          <LazyLoadImage
            className="hover:opacity-75"
            src="https://source.unsplash.com/collection/1346951/150x150?sig=1"
          />
          <LazyLoadImage
            className="hover:opacity-75"
            src="https://source.unsplash.com/collection/1346951/150x150?sig=2"
          />
          <LazyLoadImage
            className="hover:opacity-75"
            src="https://source.unsplash.com/collection/1346951/150x150?sig=3"
          />
          <LazyLoadImage
            className="hover:opacity-75"
            src="https://source.unsplash.com/collection/1346951/150x150?sig=4"
          />
          <LazyLoadImage
            className="hover:opacity-75"
            src="https://source.unsplash.com/collection/1346951/150x150?sig=5"
          />
          <LazyLoadImage
            className="hover:opacity-75"
            src="https://source.unsplash.com/collection/1346951/150x150?sig=6"
          />
          <LazyLoadImage
            className="hover:opacity-75"
            src="https://source.unsplash.com/collection/1346951/150x150?sig=7"
          />
          <LazyLoadImage
            className="hover:opacity-75"
            src="https://source.unsplash.com/collection/1346951/150x150?sig=8"
          />
          <LazyLoadImage
            className="hover:opacity-75"
            src="https://source.unsplash.com/collection/1346951/150x150?sig=9"
          />
        </div>
        <a
          href="https://www.instagram.com/tekfallsupreme/" target="_blank" rel="noreferrer"
          className="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-6"
        >
          <i className="fab fa-instagram mr-2"></i> Follow @TEKFALLSUPREME
        </a>
      </div>
    </aside>
  );
};

export default BlogAside;
