
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from "react";

const AutoSlidingNews: React.FC = () => {
  const newsHeadlines = [
    "RESILITE OFFICIAL WRESTLING MAT OF TEKFALL SUPREME",
    "SIGN UP FOR EARLY ACCESS",
    "3D ONLINE MULTIPLAYER",
    "FOLKSTYLE FIRST",
    "POST CONTENT",
    "NIL OPPORTUNITIES",
    "COLLECT GAME TOKENS​",
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    const animateSlides = () => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % newsHeadlines.length);
    };

    timerRef.current = window.setInterval(animateSlides, 2000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [newsHeadlines?.length]);

  return (
    <div
      className="sliding-news-container w-full absolute bottom-12 z-50"
      style={{
        height: '60px',
        background:
          "linear-gradient(to left, rgba(37, 37, 37, 1) 60%, rgba(255, 0, 0, 0.4) 130%)",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="headline-slide flex flex-row">
        {newsHeadlines.map((headline, index) => (
          <div
            key={index}
            className={`italic z-50 ${
              index === currentSlide ? "active" : ""
            } mr-7 text-light-blue`}
          >
            <h1 className="text-2xl hover:text-green-300 py-3 font-bold uppercase text-white">
              <Link rel="prefetch" to="/blog">{headline}</Link>
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutoSlidingNews;
