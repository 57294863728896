import React from "react";

type Option = {
  label: string;
  value: string;
};

type Props = {
  label: string;
  number?: string;
  options: Option[];
  selectedValues: string[];
  onInputChange: (values: string[]) => void;
};

const CheckboxInput: React.FC<Props> = ({ label, number, options, selectedValues, onInputChange }) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="capitalize font-semibold">{`${number} ${label}`}</p>
      <div className="flex items-center gap-2 flex-wrap">
        {options.map((option) => (
            <div
            className={`flex items-center gap-1 ${
                selectedValues.includes(option.value)
                ? "border-white"
                : "border-neutral-500"
            }`}
            key={option.value}
            >
            <input
                type="checkbox"
                id={option.value}
                name={option.value}
                value={option.value}
                checked={selectedValues.includes(option.value)}
                onChange={(e) => {
                const updatedSelection = e.target.checked
                    ? [...selectedValues, e.target.value]
                    : selectedValues.filter((value) => value !== e.target.value);
                onInputChange(updatedSelection);
                }}
            />
            <label htmlFor={option.value} className="pl-3 text-white text-lg">
                {option.label}
            </label>
            </div>
        ))}
    </div>
    </div>
  );
};

export default CheckboxInput;