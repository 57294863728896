import React from "react";
import ContentLeft from "../featureCollection/ContentLeft";
import ButtonPrimary from "../../atoms/control/ButtonPrimary";
import { Link } from 'react-router-dom';

const Double: React.FC = () => {
  return (
    <section className=" bg-black-bg py-12 md:bg-bat-black  bg-cover  bg-no-repeat">
      <div className="w-11/12 md:w-11/12 mx-auto">
        <div className="flex gap-10  items-center justify-center md:justify-between flex-col-reverse lg:flex-row  md:py-32">
          <div className="w-full">
            <div className="sm:mt-32 md:mt-20 lg:mt-0" data-aos="slide-up">
              <ContentLeft />
            </div>
          </div>
          <div
            className="flex justify-center items-center w-full"
            data-aos="fade-left"
          >
            <div className="flex items-center justify-center flex-col gap-4">
              <div className=" text-4xl font-semibold text-center font-semibold text-white">
                CREATE YOUR DIGITAL DOUBLE
              </div>
              <div className="text-center text-2xl text-white ">
                Wrestle as yourself and become a character in TEKFALL SUPREME.
                Create and join teams with your friends. Virtually connect with
                fans and brands for NIL opportunities.
              </div>
              <Link rel="prefetch" className="text-1xl" to="/doublelearn">
                <ButtonPrimary text="Learn More​" />
              </Link>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col gap-4 text-center text-white pb-20">
          <p>Be in TEKFALL SUPREME by creating your Digital Double.</p>
          <p>
            You Own Your character. Create and join teams with your friends.
            Compete against other teams. More information Coming Soon!
          </p>
          <p>
            Leverage your followers through our Name Image and Likeness (NIL)
            program.
          </p>
          <p>
            Create custom BATS-TOI Daps (digital collectibles) for in-game use,
            and to build and connect with your community. Convert your DAPS to
            physical merch and experiences for your fans.
          </p>
          <p>More information Coming Soon!</p>
        </div> */}
      </div>
    </section>
  );
};

export default Double;
