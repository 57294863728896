import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Dataset: React.FC = () => {
  return (
    <div className="bg-join-bg bg-cover pb-20">
      <section className="w-11/12 md:w-11/12 mx-auto overflow-x-hidden">
        <div className="flex justify-center items-center md:items-start md:flex-row gap-8 flex-col">
          <LazyLoadImage
            src="https://tekfall.s3.amazonaws.com/20230408_122007.jpg"
            loading="lazy"
            style={{ maxWidth: "500px", maxHeight: "500px" }}
          />
          <LazyLoadImage
            src="https://tekfall.s3.amazonaws.com/160714+45_teamshot+(1).jpg"
            className="md:mt-20"
            loading="lazy"
            style={{ maxWidth: "500px", maxHeight: "500px" }}
          />
        </div>
      </section>
    </div>
  );
};

export default Dataset;
