import React from "react";

type IProps = {
  img1: any,
  vid: any,
  num: any,
  text: any,
  titText: any
}

const MobileSecondStackCard: React.FC<IProps> = ({ img1, vid, num, text, titText }) => {
  return (
    <div className="relative flex items-end flex-col flex-wrap" style={{ maxWidth: "100%", flexWrap: "wrap" }}>
    <div className="w-full">
      <div
        className="background-other-video"
      >
        <video autoPlay muted loop playsInline>
          <source
            src={'https://tekfall-home.s3.amazonaws.com/Compressed+box_03render_compressed.mp4'}
            type="video/mp4"
          />
        </video>
      </div>
    </div>
    <div className="flex w-full h-full flex-col z-10">
        <div className="flex w-full flex-col items-start">
          <h6 className=" p-4 px-6 text-xl font-bold" 
            style={{background: '#404040', color: '#66ff66'}}>{num}</h6>
          <div className="p-4 bg-white text-left flex flex-col gap-2">
            <div className="text-black text-5xl font-semibold">{titText}</div>
            <p className="text-black">{text}</p>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div
          className="background-other-video"
        >
          <video autoPlay muted loop playsInline>
            <source
              src={vid}
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
  );
};

export default MobileSecondStackCard;
