import React from "react";
import Blogheader from "../../components/molecules/BlogHead";
import BlogNav from "../../components/molecules/BlogNav";
import BlogCard from "../../components/atoms/blogcard";
import BlogAside from "../../components/molecules/blogaside";
import { Link } from "react-router-dom";

// interface CarouselData {
//   currentIndex: number;
//   images: string[];
//   increment: () => void;
//   decrement: () => void;
// }



const Blog: React.FC = () => {
  
  
  // const [currentIndex, setCurrentIndex] = useState(0);
  
  // const getCarouselData = (): CarouselData => {
    
  //   const images = [
  //     "https://source.unsplash.com/collection/1346951/800x800?sig=1",
  //     "https://source.unsplash.com/collection/1346951/800x800?sig=2",
  //     "https://source.unsplash.com/collection/1346951/800x800?sig=3",
  //     "https://source.unsplash.com/collection/1346951/800x800?sig=4",
  //     "https://source.unsplash.com/collection/1346951/800x800?sig=5",
  //     "https://source.unsplash.com/collection/1346951/800x800?sig=6",
  //     "https://source.unsplash.com/collection/1346951/800x800?sig=7",
  //     "https://source.unsplash.com/collection/1346951/800x800?sig=8",
  //     "https://source.unsplash.com/collection/1346951/800x800?sig=9",
  //   ];
  
  
  //   const increment = (): void => {
  //     setCurrentIndex((prevIndex) =>
  //       prevIndex === images.length - 6 ? 0 : prevIndex + 1
  //     );
  //   };
  
  //   const decrement = (): void => {
  //     setCurrentIndex((prevIndex) =>
  //       prevIndex === 0 ? images.length - 6 : prevIndex - 1
  //     );
  //   };
  
  //   return {
  //     currentIndex,
  //     images,
  //     increment,
  //     decrement,
  //   };
  // };

  // const carouselData = getCarouselData();
  return (
    <body className="bg-white">
      <Blogheader />
      <BlogNav />

      <div className="container mx-auto flex flex-wrap py-6">
        <section className="w-full md:w-2/3 flex flex-col items-center px-3">
          <BlogCard
            url={"#"}
            videourl={
              "https://tekfall-home.s3.amazonaws.com/Compressed+VID_29350131_171033_998_compressed.mp4"
            }
            category={"Technology"}
            blogtitle={"3D ONLINE MULTIPLAYER"}
            author={"Mario Mercado"}
            authorurl={"#"}
            date={"June 13th, 2023"}
            paragraph="Exciting New Era: 3D Online Multiplayer Gaming Takes Center Stage.
            Immersive Experience Awaits as 3D Online Multiplayer Games Capture Attention Worldwide"
          />
          <BlogCard
            url={"#"}
            videourl={
              "https://tekfall-home.s3.amazonaws.com/Compressed+VID-20211201-WA0000_compressed+(1).mp4"
            }
            category={"Automotive, Finance"}
            blogtitle={"FOLKSTYLE FIRST"}
            author={"Mario Mercado"}
            authorurl={"#"}
            date={"April 25th, 2022"}
            paragraph="Folkstyle Wrestling Makes History as It Takes the Spotlight for the First Time.
            Groundbreaking Milestone: Folkstyle Wrestling Steps into the Limelight as the First of Its Kind"
          />
          <BlogCard
            url={"#"}
            videourl={
              "https://tekfall-home.s3.amazonaws.com/Compressed+VID-20220102-WA0002_compressed_compressed_trimmed_compressed.mp4"
            }
            category={"Sport"}
            blogtitle={"NIL OPPORTUNITIES"}
            author={"Mario Mercado"}
            authorurl={"#"}
            date={"Feb 18th, 2023"}
            paragraph="Game-Changing Opportunity: NIL Opens New Doors for Student Athletes.
            Breaking Barriers: NIL Offers Unprecedented Opportunities for Student Athletes"
          />

          <div className="flex items-center py-8">
            <Link
              to="/blog"
              className="h-10 w-10 bg-blue-800 hover:bg-blue-600 font-semibold text-white text-sm flex items-center justify-center"
            >
              1
            </Link>
            <Link
              to="/blog"
              className="h-10 w-10 font-semibold text-gray-800 hover:bg-blue-600 hover:text-white text-sm flex items-center justify-center"
            >
              2
            </Link>
            <Link
              to="/blog"
              className="h-10 w-10 font-semibold text-gray-800 hover:text-gray-900 text-sm flex items-center justify-center ml-3"
            >
              Next <i className="fas fa-arrow-right ml-2"></i>
            </Link>
          </div>
        </section>
        <BlogAside />
      </div>
    </body>
  );
};

export default Blog;
