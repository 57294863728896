import React from "react";
import AutoSlidingNews from "../../molecules/newsheadlines";
import HeroArticle from "../../molecules/hero";
import LazyLoad from 'react-lazy-load'

type IProps = {
  openM: any
}
const HomeHero: React.FC<IProps> = ({ openM }) => {
  return (
    <>
      <section
        className="bg-hero-bg lg:bg-black min-h-screen lg:bg-none bg-cover bg-center md:bg-top relative"
      >
        <div className="w-11/12 mx-auto lg:w-full h-full relative">
          <div className="flex  h-screen  gap-8 md:gap-0 item-center justify-between">
            <div className="flex justify-center lg:bg-bg-lat items-center w-full h-full item-center md:pt-0">
              <HeroArticle openModel={openM} />
            </div>

            {/* <LazyLoad> */}
              <LazyLoad className="w-full hidden bg-hero-bg bg-cover bg-center lg:block custompt">
                <div></div>
            </LazyLoad>
          </div>
          <div>
            <AutoSlidingNews />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeHero;
