import * as React from "react";
import PrivacyPolicy from "../../components/molecules/PrivacyPolicy";

// markup
const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
        <PrivacyPolicy />
    </>
  );
};

export default PrivacyPolicyPage;
