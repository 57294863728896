import React from "react";
import Meta from "../../components/molecules/meta";
import Dataset from "../../components/molecules/dataset";

const Mocap = () => {
  return (
    <div>
      <Meta />
      <Dataset />
    </div>
  );
};

export default Mocap;
