import React from "react";
import CardVideoCollection from "../../atoms/card/CardVideoCollection";
import CardVideoPlayCollection from "../../atoms/card/CardVideoPlayCollection";

const ContentLeft: React.FC = () => {
  return (
    <div className="flex-grow-0 flex-shrink">
      <div className="relative flex flex-col items-center mb-6 lg:mb-0 justify-center sm:flex-row  gap-8">
        {/* <img src="/NFT-frame-art.png" alt="featured" className='w-full' /> */}

        <div className="w-64">
          <CardVideoCollection
            title="Collection of Videos"
            author="Fullmatch"
            authorVerified={true}
            video="https://tekfall.s3.amazonaws.com/Eddie_TA2.mp4"
          />
        </div>

        <div className="w-64 xl:relative xl:top-24">
          <CardVideoPlayCollection
            title="Collection of Videos"
            author="Fullmatch"
            authorVerified={true}
            video="https://tekfall.s3.amazonaws.com/Compressed+FacialAnim_+(1)_compressed.mp4"
          />
        </div>
      </div>
    </div>
  );
};

export default ContentLeft;
