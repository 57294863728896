import React from "react";

const BlogNav: React.FC = () => {
  return (
    <nav
      className="w-full py-4 border-t border-b bg-gray-100"
      data-open="false"
    >
      <div className="hidden w-full items-center flex-grow justify-center sm:flex sm:items-center sm:w-auto">
        <ul className="flex justify-center items-center">
          <li className="mr-3">
            <a
              className="inline-block py-2 px-4 text-black font-bold no-underline"
              href="/blog"
            >
              Character
            </a>
          </li>
          <li className="mr-3">
            <a
              className="inline-block text-gray-600 no-underline hover:text-black hover:text-underline py-2 px-4"
              href="/blog"
            >
              Environment
            </a>
          </li>
          <li className="mr-3">
            <a
              className="inline-block text-gray-600 no-underline hover:text-black hover:text-underline py-2 px-4"
              href="/blog"
            >
              Wrestling Moves
            </a>
          </li>
          <li className="mr-3">
            <a
              className="inline-block text-gray-600 no-underline hover:text-black hover:text-underline py-2 px-4"
              href="/blog"
            >
              Wearables
            </a>
          </li>
          <li className="mr-3">
            <a
              className="inline-block text-gray-600 no-underline hover:text-black hover:text-underline py-2 px-4"
              href="/blog"
            >
              Game Modes
            </a>
          </li>
          <li className="mr-3">
            <a
              className="inline-block text-gray-600 no-underline hover:text-black hover:text-underline py-2 px-4"
              href="/blog"
            >
              Game Mechanics
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default BlogNav;
