import React from "react";
import BrandWhite from "../../atoms/brandwhite";

const OrderNow: React.FC = () => {
  return (
    <section className="bg-hero-bg bg-cover bg-center md:bg-top ">
      <div className="w-11/12 md:w-11/12 mx-auto custompt2 pb-10">
        <div className="flex items-center justify-center">
          <div className="md:w-3/4">
            <BrandWhite img={"https://tekfall.s3.amazonaws.com/tekfallsupreme_logo+(1).png"} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderNow;
