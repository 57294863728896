
import React from "react";

import StackCard from "../../atoms/card/StackCard";

const CardOne: React.FC = () => {

  return (
          <div className="w-full h-screen mt-10 relative" data-aos="fade-right">
            <div className="flex flex-col items-center justify-center">
              <StackCard
                imgUrl={"https://tekfall.s3.amazonaws.com/BatsToi_AR-Cards_V1-Front(1).jpg"}
                num={"01"}
                titText="CREATE, COLLECT, TRADE & PLAY"
                text="Build your collection to wear or trade in TEKFALL SUPREME or to show in AR/VR experiences."
              />
            </div>
          </div>
  );
}
export default CardOne;