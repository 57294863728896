import React, { useEffect, useState } from 'react';

interface PreorderData {
  gameSystem: string;
  age: string;
  gender: string;
  classification: string;
}

const PreorderTable: React.FC = () => {
  const [data, setData] = useState<PreorderData[]>([]);
  const [sortedData, setSortedData] = useState<PreorderData[]>([]);
  const [totalPreorders, setTotalPreorders] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [showText, setShowText] = useState(true);

  useEffect(() => {
    // Set interval to toggle the visibility of the text every second
    const interval = setInterval(() => {
      setShowText((prevShowText) => !prevShowText);
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint for getting pre-order data
      const response = await fetch('https://tekfall-supreme-backened.vercel.app/cart/pre-order');
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.json();
      console.log('data', jsonData)
      setData(jsonData?.orders);
      setSortedData(jsonData?.orders);
      setTotalPreorders(jsonData?.orders.length);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error gracefully, show error message to the user or retry fetching data
    }
  };

  const sortData = (column: keyof PreorderData) => {
    const sorted = [...sortedData].sort((a, b) => {
      if (a[column] < b[column]) return -1;
      if (a[column] > b[column]) return 1;
      return 0;
    });

    setSortedData(sorted);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
  };

  const paginatedData = sortedData.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

  return (
      <div className="lg:px-12 mt-10 px-4 overflow-x-auto min-h-screen mb-12 pb-5 flex flex-col justify-start">
        <div className='flex flex-row flex-wrap items-center justify-between'>
          <h2 className="text-white text-xl mb-4">List of {data?.length}</h2>
          <div style={{ color: '#66ff66' }} className={`text-lg upercase ${showText ? 'visible' : 'invisible'}`}>
            HURRY UP AND PRE-ORDER BEFORE THE LIST GETS FULL!
          </div>
        </div>
      <table className="table-auto w-full tabel-mobile">
        <thead>
          <tr className="bg-gray-800 text-left">
            <th
              className="px-4 py-2 text-white cursor-pointer"
              onClick={() => sortData('gameSystem')}
            >
              Game System
            </th>
            <th
              className="px-4 py-2 text-white cursor-pointer"
              onClick={() => sortData('age')}
            >
              Age
            </th>
            <th
              className="px-4 py-2 text-white cursor-pointer"
              onClick={() => sortData('gender')}
            >
              Gender
            </th>
            <th
              className="px-4 py-2 text-white cursor-pointer"
              onClick={() => sortData('classification')}
            >
              Classification
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item, index) => (
            <tr key={index} className="bg-gray-700">
              <td className="px-4 py-2 text-white">{item.gameSystem}</td>
              <td className="px-4 py-2 text-white">{item.age}</td>
              <td className="px-4 py-2 text-white">{item.gender}</td>
              <td className="px-4 py-2 text-white">{item.classification}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-between mt-4">
        <div>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
            className="px-2 py-1 mr-2 bg-gray-600 text-white rounded"
          >
            Previous
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={(currentPage + 1) * pageSize >= totalPreorders}
            className="px-2 py-1 bg-gray-600 text-white rounded"
          >
            Next
          </button>
        </div>
        <div>
          <span className="text-white mr-2">
            Page{' '}
            <strong>
              {currentPage + 1} of {Math.ceil(totalPreorders / pageSize)}
            </strong>
          </span>
          <span>
            | Items per page:{' '}
            <select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              className="px-2 py-1 bg-gray-600 text-white rounded"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PreorderTable;