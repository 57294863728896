import React from "react";
import MainModal from "../../atoms/Modals/MainModal";
import TrailerVideo from "../../atoms/card/TrailerVideo";

type IProps = {
  isOpen: any,
  closeModal: any
}

const ModelVideo: React.FC<IProps> = ({ isOpen, closeModal }) => (
  <MainModal modalOpen={isOpen} setModalOpen={closeModal}>
    {/* {isOpen && (
      <div
        className="fixed inset-0 flex items-center bg-black justify-center opacity-95"
        style={{ zIndex: 99999 }}
      >
        <div className="absolute inset-0 bg-black"></div>
        <div
          className="w-full max-w-lg mx-auto p-4 bg-black rounded-lg"
          style={{ zIndex: 999999999 }}
        >
          <span
            className="absolute top-10 right-10 text-3xl md:text-5xl font-bold cursor-pointer text-white"
            onClick={closeModal}
          >
            &times;
          </span>
          <div className="hidden md:block">
            <video
              src="https://res.cloudinary.com/skiltime/video/upload/v1689387318/TRAILER_GAMEPLAY_y1pzkh.mp4"
              playsInline
              autoPlay
            />
          </div>
          <div className="aspect-w-16 aspect-h-9 md:hidden">
            <iframe
              src="https://res.cloudinary.com/skiltime/video/upload/v1689387318/TRAILER_GAMEPLAY_y1pzkh.mp4"
              className="w-full h-full"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    )} */}

    <div className="w-screen h-screen xl:relative xl:top-35">
      <TrailerVideo
        title="Collection of Videos"
        author="Fullmatch"
        authorVerified={true}
        video="https://tekfall-home.s3.amazonaws.com/Compressed+TRAILER+%2B+GAMEPLAY.mov"
      />
    </div>
  </MainModal>
);

export default ModelVideo;
