import React, { useEffect, useState } from "react";
import HomeHero from "../organisms/HomeHero";
// import { DarkModeContext } from "../DarkModeProvider";
import ListAuction from "../molecules/list/ListAuction";
import Physical from "../molecules/Physical";
import Double from "../molecules/double";
import PreOrder from "../organisms/preorder";
import SwipperCom from "../organisms/swipper";
import OrderNow from "../molecules/ordernow";
import LimitedOffer from "../molecules/limited";
import LearnMore from "../organisms/learnmore";
import ModelVideo from "../molecules/popup";
import "aos/dist/aos.css";
import Aos from "aos";

const HomePage: React.FC = () => {
  // const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div>
      <ModelVideo isOpen={isOpen} closeModal={() => setIsOpen(false)} />
      <div>
        <HomeHero openM={() => setIsOpen(true)} />
        <PreOrder />
        <ListAuction />
        {/* <CommingSoon /> */}
        <LearnMore />
        <Physical />
        <SwipperCom />

        <Double />
        <OrderNow />
        <LimitedOffer />
      </div>
    </div>
  );
};

export default HomePage;
