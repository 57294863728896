import * as React from "react";
import PreorderTable from "../../components/organisms/preorder/PreOrderTable";

// markup
const PreOrderListPage: React.FC = () => {
  return (
    <>
        <PreorderTable />
    </>
  );
};

export default PreOrderListPage;
