import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Meta: React.FC = () => {
  return (
    <section className="bg-join-bg bg-cover">
      <div>
        <div className="flex gap-8 flex-col  py-14 md:flex-row md:pb-20 md:pt-0 justify-between">
          <div className="flex  justify-center items-center sm:items-start flex-col gap-8 md:pl-20 w-full">
            <LazyLoadImage
              src="https://tekfall.s3.amazonaws.com/MetaMotionAI_logo_1__1_-removebg-preview.png"
              className="py-6 md:py-2"
              style={{ maxWidth: "300px" }}
            />
            <p className="text-center sm:text-left text-white">
              TEKFALL SUPREME puts you on the mat using our proprietary
              MetaMotion AI™ technology for authentic wrestling motion capture.{" "}
            </p>
            <p className="text-center text-white sm:text-left">
              Our data sets have thousands of actual wrestling animations where
              we use machine learning and other cutting-edge AI tools to convert
              the data into a robust combat system, delivering you a one-of-kind
              gameplay experience that is easy to learn but difficult to master.
            </p>
          </div>
          <div className="background-video">
            <video
              autoPlay
              muted
              loop
              playsInline
              className="w-full"
              style={{ minHeight: "100%" }}
            >
              <source
                src="https://tekfall.s3.amazonaws.com/Compressed+Eddie_TA_compressed.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Meta;
