import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";
import { IoClose } from "react-icons/io5";

type IProps = {
  modalOpen: any, 
  setModalOpen: any, 
  children: any
}

const MainModal: React.FC<IProps> = ({ modalOpen, setModalOpen, children }) => {
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <Transition show={modalOpen} as={Fragment} appear>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto text-center"
          initialFocus={cancelButtonRef}
          onClose={() => setModalOpen(false)}
        >
          <div className="min-h-[100%] flex flex-col items-center justify-center px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-400"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-90" />
            </Transition.Child>
            <span
              className="inline-block h-[100%] align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {children}
            </Transition.Child>
            <div className="absolute right-5 top-5">
              <button
                onClick={() => setModalOpen(false)}
                type="button"
                className={`
                    w-12 h-12 flex flex-colo items-center justify-center 
                    text-center bg-red-700 text-subMain rounded-full 
                `}
              >
                <IoClose className="h-8 w-8 text-white" />
              </button>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default MainModal;
export {};