import React from "react";
import { BsPlay } from "react-icons/bs";
import BrandWhite from "../../atoms/brandwhite";

type IProps = {
  openModel: any
}
const HeroArticle: React.FC<IProps> = ({ openModel }) => {
  return (
    <div
      className="flex items-center justify-center flex-col gap-4"
      data-aos="fade-right"
    >
      <div className="md:w-3/4 flex flex-col items-center">
        <BrandWhite img={"https://tekfall.s3.amazonaws.com/playstation-02-cropped.svg"} />
        <BrandWhite img={"https://tekfall.s3.amazonaws.com/tekfallsupreme_logo+(1).png"} />
        <div
          className="text-white italic text-3xl text-center mt-4"
          style={{ maxWidth: "450px" }}
        >
          THE WRESTLING VIDEO GAME YOU HAVE BEEN WAITING FOR.​
        </div>
      </div>
      <div
        className="flex gap-2 items-center nav-button-signin bg-black border-2 border-white px-8 py-2  rounded-3xl hover:bg-red-500 cursor-pointer mb-10 md:mb-0"
        onClick={openModel}
      >
        <p className="text-white ">GAMEPLAY</p>
        <BsPlay className="text-white text-3xl" />
      </div>
    </div>
  );
};

export default HeroArticle;
