import React from "react";

type Props = {
  label: string;
  number?: string;
  options: string[];
  selectedValue: string;
  onInputChange: (value: string) => void;
};

const RadioBoxInput: React.FC<Props> = ({ label, number, options, selectedValue, onInputChange }) => {
  return (
    <div className="w-[100%] md:w-[100%] mx-auto m-5">
      <p className="capitalize font-semibold">{`${number} ${label}`}</p>
      <div className="flex items-center gap-2 flex-wrap">
        {options.map((option) => (
            <div
            className={`flex items-center gap-1 ${
                selectedValue === option ? "border-white" : "border-neutral-500"
            }`}
            key={option}
            >
            <input
                type="radio"
                id={option}
                name={label}
                value={option}
                checked={selectedValue === option}
                onChange={() => onInputChange(option)}
            />
            <label htmlFor={option} className="pl-3 text-white text-lg">
                {option}
            </label>
            </div>
        ))}
    </div>
    </div>
  );
};

export default RadioBoxInput;