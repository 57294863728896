import React from "react";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { RiLinkedinFill } from "react-icons/ri";

const Conversation: React.FC = () => {
  return (
    <div className="flex flex-col gap-8 ">
      <h5 className="text-white text-2xl font-semibold">
        JOIN THE CONVERSATION
      </h5>
      <ul id="contact-form" className="flex flex-row gap-4 flex-wrap">
        <li className="flex-grow-0 flex-shrink">
          <a
            className="text-white w-10 h-10 rounded-lg bg-black hover:bg-pink duration-300 block relative"
            href="https://web.facebook.com/batstoiofficial"
            target="_blank" rel="noreferrer"
          >
            <i className="absolute top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4">
              <FaFacebookF />
            </i>
          </a>
        </li>

        <li className="flex-grow-0 flex-shrink ">
          <a
            className="text-white w-10 h-10 rounded-lg bg-black hover:bg-pink duration-300 block relative"
            href="https://twitter.com/TEKFALLSUPREME"
            target="_blank" rel="noreferrer"
          >
            <i className="absolute top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4">
              <BsTwitter />
            </i>
          </a>
        </li>

        <li className="flex-grow-0 flex-shrink">
          <a
            className="text-white w-10 h-10 rounded-lg bg-black hover:bg-pink duration-300 block relative"
            href="https://www.instagram.com/tekfallsupreme"
            target="_blank" rel="noreferrer"
          >
            <i className="absolute top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4">
              <BsInstagram />
            </i>
          </a>
        </li>

        <li className="flex-grow-0 flex-shrink">
          <a
            className="text-white w-10 h-10 rounded-lg bg-black hover:bg-pink duration-300 block relative"
            href="https://linkedin.com"
            target="_blank" rel="noreferrer"
          >
            <i className="absolute top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4">
              <RiLinkedinFill />
            </i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Conversation;
