
import { Link } from 'react-router-dom';
import React from "react";

type IProps = {
  text: any,
  url: any
}

const FootItem: React.FC<IProps> = ({ text, url }) => {
  return (
    <Link rel="prefetch" to={url}>
      <li className="hover:text-pink cursor-pointer text-lg">{text}</li>
    </Link>
  );
};

export default FootItem;
