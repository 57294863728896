import React from 'react';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
};

const BasicButton: React.FC<Props> = ({ type = 'button', disabled = false, onClick, children }) => {
  return (
    <button
      type={type}
      className={`flex bg-red-500 w-full text-center text-white items-center justify-center py-2 rounded-2xl ${
        disabled ? 'opacity-50 pointer-events-none' : ''
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default BasicButton;